<template>
  <div>
    <el-table :data="tableData" class="form-table">
      <el-table-column prop="key" label="Key">
        <template slot-scope="scope">
          <el-input v-model="scope.row.key" placeholder="Key" @input="updateBody"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="value" label="Value">
        <template slot-scope="scope">
          <el-input v-model="scope.row.value" placeholder="Value" @input="updateBody"></el-input>
        </template>
      </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <el-button
            :id="`delete-param-button-${scope.$index}`"
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="removeBodyParam(scope.$index)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button
      id="add-param-button"
      type="primary"
      size="mini"
      style="margin-top: 10px"
      @click="addBodyParam"
      >Add Parameter</el-button
    >
  </div>
</template>

<script>
import _ from "lodash";

const defaultTableDataRow = { key: "", value: "" };
const MAX_TABLE_DATA_LENGTH = 500; // Maximum number of rows

export default {
  props: ["body"],
  data() {
    return {
      tableData: [],
      queryString: this.body,
    };
  },
  mounted() {
    this.tableData = this.convertQueryStringToTableData();
  },
  methods: {
    convertQueryStringToTableData() {
      try {
        const tableDataArray = this.queryString
          .replace(/^"|"$/g, "")
          .split("&")
          .map((pair) => {
            if (!pair.includes("=")) {
              return defaultTableDataRow;
            }
            const [key, value] = pair.split("=");
            return {
              key,
              value: value.toString(),
            };
          });
        return _.isEmpty(tableDataArray) ? [{ ...defaultTableDataRow }] : tableDataArray;
      } catch {
        return [{ ...defaultTableDataRow }];
      }
    },
    convertTableDataToQueryString() {
      const paramsObject = this.tableData
        .map((item) => {
          const key = item.key;
          const value = item.value;
          return `${key}=${value}`;
        })
        .join("&");
      this.queryString = paramsObject;
    },
    updateBody: _.debounce(function () {
      this.updateTableData();
    }, 1000),
    updateTableData() {
      this.convertTableDataToQueryString();
      this.$emit("update:body", this.queryString);
    },
    addBodyParam() {
      if (this.tableData.length < MAX_TABLE_DATA_LENGTH) {
        this.tableData.push({ ...defaultTableDataRow });
        this.updateBody();
      } else {
        this.$notify.warning({
          title: "Unable to add more rows",
          message: `Maximum limit of ${MAX_TABLE_DATA_LENGTH} rows reached.`,
          position: "bottom-right",
        });
      }
    },
    removeBodyParam(index) {
      this.tableData.splice(index, 1);
      this.updateBody();
    },
  },
  watch: {
    body(newBody) {
      // Prevent table suddenly updated after user input something on table
      if (newBody === this.queryString) {
        return;
      }
      this.queryString = newBody;
      this.tableData = this.convertQueryStringToTableData();
    },
  },
};
</script>

<style scoped>
.form-table {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.form-table .el-table-column {
  border-right: 1px solid #ddd;
}

.form-table .el-input {
  width: 100%;
}

.form-table .el-table__header {
  font-size: 0.8em;
}
</style>
